import { AlgoliaAuction } from '@nx/algolia'
import { HeroImageCarousel } from '@nx/fire/hero-image-carousel'
import { Section } from '@nx/fire/section'
import VisuallyHidden from '@nx/fire/visually-hidden'
import { HeroCarouselItemsResponse } from '@nx/get-carousel-items'
import { Brand, NewsAndVideosItem } from '@nx/global-types'
import { getTranslate } from '@nx/translations'
import { TypesenseAuction } from '@nx/typesense'

import { HeroImageCarouselInfoBox } from '@web/components/common/HeroImageCarouselInfoBox'
import { populateUrl } from '@web/helpers/utils/urlBuilders/populateUrl'

import { MetaData } from '../../../common/MetaData'
import { NewsAndVideos } from '../../../common/NewsAndVideos'
import { HomePageAuctions } from '../../../home'
import { StyledNewsAndVideosSection } from './CornetteHomePage.styles'
import translate from './CornetteHomePage.translate.json'

const { text } = getTranslate(translate)

export const CornetteHomePage = ({
  carouselItems,
  auctions,
  newsAndVideos,
}: CornetteHomePage) => (
  <>
    <MetaData
      name={text('cornetteHomePage.meta.title')}
      brand={Brand.cornette}
      description={text('cornetteHomePage.meta.description')}
      imageUrl={populateUrl('/web-assets/cornette-logo.png')}
    />

    <VisuallyHidden as="h1">Bonhams Cornette de Saint-Cyr</VisuallyHidden>

    {!!carouselItems.length && (
      <Section verticalSpacing="none" bleed="partial">
        <HeroImageCarousel
          slides={carouselItems.map((item) => ({
            id: item.id,
            smallImage: item.mobile_image,
            largeImage: item.image,
            title: item.title,
            infoBox: (
              <HeroImageCarouselInfoBox
                title={item.title}
                description={item.desc}
                dateAndLocation={item.date_and_location}
                link={item.link}
                ctaTitle={item.ctaTitle}
              />
            ),
          }))}
        />
      </Section>
    )}

    {!!auctions.cornette.length && (
      <Section>
        <HomePageAuctions
          pageIdPrefix="cornette"
          title={text('cornetteHomePage.auctions')}
          auctions={auctions.cornette}
        />
      </Section>
    )}

    {!!newsAndVideos.length && (
      <StyledNewsAndVideosSection
        background="highlight"
        verticalSpacing="top-only"
      >
        <NewsAndVideos
          title={text('cornetteHomePage.news.title')}
          items={newsAndVideos.map(({ id, title, type, image }) => ({
            id,
            title,
            type,
            headingTag: 'h3',
            image: image
              ? populateUrl(image.url, image.URLParams, {
                  width: '640',
                })
              : '',
          }))}
          trackingCategory="Home Page - News and Stories"
        />
      </StyledNewsAndVideosSection>
    )}
  </>
)

export interface CornetteHomePage {
  carouselItems: HeroCarouselItemsResponse
  auctions: {
    cornette: (AlgoliaAuction | TypesenseAuction)[]
  }
  newsAndVideos: NewsAndVideosItem[]
}
