import { createAxiosClient } from '@nx/axios-client'
import { BonhamsCookies } from '@nx/global-types'

import { UserAddress, UserAddressesResponse } from './userAddresses.types'

export async function getUserAddresses(
  clientNo: number,
  baseUrl: string,
  accessTokenSSR?: BonhamsCookies['bonhams_access'],
  refreshTokenSSR?: BonhamsCookies['bonhams_token']
): Promise<UserAddress[]> {
  const authenticatedAxiosClient = createAxiosClient({
    accessTokenSSR,
    refreshTokenSSR,
    baseURL: baseUrl,
  })

  return authenticatedAxiosClient
    .get<UserAddressesResponse>(`/api/v1/client/address/${clientNo}`)
    .then((response) => response.data)
}
